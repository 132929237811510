/* This is the page to put custom style */
main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
a {
  text-decoration: none;
  color: #343a40;
}
a:hover {
  text-decoration: underline;
}

/* To change the text color to #139745 */
header {
  background-image: linear-gradient(to right, #ffffff 0%, #139745 100%);
  color: black; /* Adjust text color as needed for readability */
  /* Other styles for padding, display, etc., based on your design */
}
.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link {
  color: #139745;
}

.custom-dropdown-item:hover {
  background-color: #139745; /* Replace with your desired color */
  color: #ffffff; /* Replace with your desired text color */
}

.nav-item .dropdown-menu .custom-dropdown-item.active,
.nav-item .dropdown-menu .custom-dropdown-item:active {
  background-color: #139745; /* Replace with your desired color */
  color: #ffffff; /* Replace with your desired text color */
}

/* To change the color of Product nav item and make it bold */
.navbar-light .nav-link,
.navbar-light .navbar-nav .nav-link {
  color: #010101 !important;
  font-weight: bold;
}

.navbar-dark .dropdown-menu .dropdown-item {
  color: #010101 !important;
}

/* nav hover and background color change on hover remove arrow */
.navbar-nav .nav-link:hover {
  background-color: #e5e5e5;
}

.navbar-nav .nav-link::after {
  display: none;
}

.navbar-nav {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dropdown on hover */
.dropdown:hover > .dropdown-menu {
  display: block;
}

/* Header.css */
/* Define styles for large screens */
.large-logo {
  display: block;
  max-width: 240px; /* Show the large logo */
}

/* Define styles for small screens */
.small-logo {
  display: none;
  max-width: 48px; /* Hide the small logo on small screens */
}

/* Use a media query to adjust the display property for small screens */
@media (max-width: 767px) {
  .large-logo {
    display: none;
    /* Hide the large logo on small screens */
  }
  .small-logo {
    display: block; /* Show the small logo on small screens */
  }
}

.frequentlyBought-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Center the text below the icon on small and medium screens */
@media (max-width: 991px) {
  .location-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* Add a margin between the icon and text if needed */
  .location-link i {
    margin-bottom: 5px; /* Adjust the margin as needed */
  }
}

.image-container {
  position: relative;
  width: 100%; /* Full width of the container */
  padding-top: 100%; /* Keeps it square */
  margin: 0 auto; /* Center the square if needed */
}

.category-image {
  position: absolute;
  top: 10%; /* 10% from top to create 80% height centered */
  left: 10%; /* 10% from left to create 80% width centered */
  right: 10%; /* 10% from right to maintain the center alignment */
  bottom: 10%; /* 10% from bottom to maintain the center alignment */
  width: 80%;
  height: 80%;
  object-fit: cover;
  margin: auto; /* This will help in centering the image */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.category-card:hover {
  transform: translateY(-5px); /* Slight lift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.text {
  font-size: 14px; /* Adjust font size here */
  color: #333; /* Text color */
  text-align: center;
  padding: 12px 0; /* Increased padding for more space below image */
}

/* Center the text below the icon on small and medium screens */
@media (max-width: 991px) {
  .signin-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* Add a margin between the icon and text if needed */
  .signin-link i {
    margin-bottom: 5px; /* Adjust the margin as needed */
  }
}
/* Display the SearchBox inline on screens larger than or equal to medium */
@media (min-width: 768px) {
  .d-flex.align-items-center {
    display: flex;
  }
}

/* Display the SearchBox as a block on screens smaller than medium */
@media (max-width: 767px) {
  .d-flex.align-items-center {
    display: block;
  }
}

/* all buttons */
.button-primary {
  background-color: #fff;
  border: 2px solid #139745; /* Green color for the outline */
  border-radius: 20px;
  color: #139745; /* Green color for the text */
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: none;
}

.button-mini {
  background-color: #4bb543;
  border-radius: 20px;
  color: #fff;
  font-size: 14px; /* Adjust the font size to make the button smaller */
  font-weight: bold;
  padding: 8px 16px; /* Adjust the padding to make the button smaller */
  cursor: pointer;
  box-shadow: none;
}

/* styles.css */
.custom-button {
  background-color: #139745; /* Green color */
  border-color: #117a30; /* Darker green for border for some contrast */
}

.custom-button:hover {
  background-color: #117a30; /* Slightly darker green on hover */
  border-color: #0c5a22;
}

.button-primary:hover {
  background-color: #139745; /* Green color on hover */
  color: #fff; /* White text on hover */
  outline: none; /* Remove the outline on hover */
  box-shadow: none;
}

.btn-product:focus {
  outline: none;
}

.btn-product:active {
  background-color: #139745 !important;
  border-color: #139745 !important;
  box-shadow: none !important;
}

.custom-nav-dropdown {
  border: 1px solid #dee2e6; /* Example gray border, change color as needed */
  border-radius: 0.25rem; /* Default Bootstrap border-radius */
}

/* To style the dropdown menu specifically */
.custom-nav-dropdown .dropdown-menu {
  border-radius: 0.25rem; /* Ensures the dropdown menu also has rounded corners */
}

.custom-navbar {
  background-color: #fff;
  padding-left: 20px;
}

.qty-button {
  background-color: #139745;
  border: none;
}
/* Product.css */

.product-card:hover {
  border: 1px solid #139745; /* Add a strong border on hover with your desired color */
}

/* Below is for productNav text to make it smaller */
.smaller-text {
  font-size: 16px; /* You can adjust the size as needed */
}

/* Add this CSS to your existing stylesheet or create a new one */
.whatsapp-link {
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #25d366; /* Add a border around the link for better visibility */
  border-radius: 4px;
  background-color: #ffffff; /* Add a background color to the link */
  color: rgb(18, 18, 18); /* Set the text color to white */
  text-decoration: none; /* Remove default underline */
  bottom: 24px;
  right: 36px;
}

/* On hover, change the background color and cursor style */
.whatsapp-link:hover {
  background-color: #c6d3d2; /* Change the background color on hover */
  cursor: pointer;
}

/* Make the WhatsApp link responsive */
@media (max-width: 767px) {
  .whatsapp-link {
    bottom: 70px;
    right: 20px;
  }
}

.badge {
  /* padding: 0 3px;
  background-color: #068225;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  position: relative;
  top: -10px;
  right: 1px;
  width: 1rem; */
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #068225;
  color: white;
  border-radius: 20%;
  padding: 2px 4px;
  font-size: 12px;
}
.cart {
  /* margin-left: 10px; Adjust the margin value to add the desired space */
  position: relative;
  display: inline-block;
}

.discount-badge {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 6px;
  font-size: 12px;
}
.bg-light-green {
  background-color: rgba(20, 202, 65, 0.502);
}
.original-price {
  text-decoration: line-through;
}
.product-name {
  min-height: 50px; /* Adjust this value as needed */
  display: flex;
  align-items: center;
}

/* Add these styles to your CSS */
.quantity-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.2rem;
}

.qty-number {
  font-size: 1.2rem;
  margin: 0 10px;
}
.responsive-button {
  background-color: white;

  overflow: hidden; /* Hide text overflow */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
  white-space: nowrap; /* Prevent line breaks */
  font-size: 0.8rem; /* Initial font size */
  border-color: #117a30;
  color: #117a30;
  /* Media query for smaller screen sizes */
  @media (max-width: 768px) {
    font-size: 0.7rem; /* Reduce font size for smaller screens */
  }
}

.responsive-button:active,
.responsive-button:focus {
  background-color: #139745 !important;
  outline: none;
  box-shadow: none;
  border: none;
  color: white;
}

.responsive-button:hover {
  background-color: #117a30 !important;
}

.clickable {
  color: rgb(37, 37, 197); /* Change the color to indicate a link */
  text-decoration: underline; /* Add an underline to indicate a link */
  cursor: pointer; /* Change the cursor to a pointer to indicate interactivity */
}

/* CSS */
.scrolling-container {
  display: flex;
  align-items: center;
  width: 100%;
}
/* This is code start */
.cover {
  position: relative;
  padding: 0px 30px;
  margin-top: 100px;
}

.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.scroll-images {
  position: relative;
  width: 100%;
  padding: 40px 0px;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.scroll-images::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
}

.scroll-images::-webkit-scrollbar-thumb {
  background-color: black;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 25px;
}

/* This is code end */

.scrolling-text {
  flex: 2;
  background-color: #0072bb;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px; /* Add spacing to separate category and text */
}

.scrolling-text span {
  animation: scroll 10s linear infinite; /* Adjust the animation duration */
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  padding-right: 20px; /* Add space between text spans */
  display: inline-block;
}
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
  200% {
    transform: translateX(100%);
  }
}

/* SpecialOffers.css */
.selected {
  border: 2px solid #0072bb;
  border-radius: 10px;
}

/* Add more styling as needed */
.map-layout {
  height: 100vh;
  width: 100vw;
}

.map-container {
  height: 100%;
  width: 100%;
}

/* Slider buttons for products */

/* Add this to your CSS */
.round-arrow.left-arrow {
  left: 24px; /* Adjust the left position as needed */
  z-index: 1; /* Ensure the left arrow is above other elements */
}

.round-arrow.right-arrow {
  right: 24px; /* Adjust the right position as needed */
  z-index: 1; /* Ensure the right arrow is above other elements */
}

/* Center the arrows vertically */
.round-arrow {
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: #068225;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  cursor: pointer;
}

/* Center the arrow icon within the button */
.round-arrow svg {
  margin-top: 7px; /* Adjust the margin-top as needed */
  margin-left: 10px; /* Adjust the margin-left to center horizontally */
}

/* Style the arrows on hover if needed */
.round-arrow:hover {
  background-color: #25d366; /* Change the background color on hover */
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.carousel-container {
  margin-top: 120px; /* Adjust this margin to leave space for the header */
  z-index: 500; /* Adjust the z-index value */
}

.rounded-image {
  border-radius: 10px; /* Adjust this value to get the desired roundness */
}

.custom-checkbox .form-check-input:checked {
  background-color: #139745;
  border-color: #139745;
}

.brand-btn {
  background-color: #139745;
  border-color: #139745;
}

.brand-btn:hover {
  background-color: #0f7a3c;
  border-color: #0e6934;
}

.custom-form-control {
  background-color: #fff; /* Light background for input */
  border: 1px solid #ccc; /* Subtle borders for inputs */
  border-radius: 4px; /* Rounded corners for the input fields */
  padding: 10px 12px; /* Comfortable padding inside inputs */
  font-size: 16px; /* Slightly larger font size for better readability */
}

.custom-form-control:focus {
  border-color: #139745; /* Highlight input when focused */
  box-shadow: 0 0 8px rgba(19, 151, 69, 0.2); /* Subtle shadow on focus */
}

.custom-form-label {
  font-size: 18px; /* Larger label size for clarity */
  color: #333; /* Darker color for better readability */
  margin-bottom: 5px; /* Space between label and input field */
}

/* Applying margin and padding for overall spacing */
.form-group {
  margin-bottom: 1rem; /* Consistent bottom margin */
}

@media (max-width: 768px) {
  .custom-form-control,
  .custom-form-label {
    font-size: 14px; /* Smaller font size for mobile devices */
  }
}

/* Footer */
@media (max-width: 576px) {
  .footer-link {
    font-size: 14px;
    padding: 8px 0;
  }

  .social-link {
    font-size: 16px; /* Adjust the font size as needed */
    color: #fff; /* Set the text color (white, in this case) */
    text-decoration: none; /* Remove underline from links */
  }

  .social-link:hover {
    text-decoration: underline; /* Optionally add an underline on hover */
  }

  .social-icon {
    font-size: 24px; /* Adjust the font size of the icons as needed */
    vertical-align: middle; /* Align icons with the text vertically */
  }
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-button {
  width: 30px;
  height: 30px;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  color: black;
  margin: 0 5px; /* Adjust margin as needed */
}

.quantity-input {
  width: 30px; /* Adjust width as needed */
  height: 30px;
  text-align: center; /* Center align the text */
  border: 1px solid #ced4da; /* Add a border for visual separation */
  border-radius: 5px; /* Add some border radius for a rounded appearance */
}

/* In your custom CSS file */
.btn-custom:hover,
.btn-custom:active,
.btn-custom:focus {
  background-color: #8fd27e !important; /* Use your desired color */
  border-color: #8fd27e !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}

/* Custom Dropdown Toggle Styles */
.custom-dropdown-toggle {
  background-color: #f8f9fa !important; /* Change to match your bg-neutral-200 */
  color: black !important; /* Text color */
  border: 0 !important; /* Remove border */
}

/* Custom Dropdown Menu Styles */
.custom-dropdown-item {
  color: black !important; /* Text color for dropdown items */
  background-color: #f8f9fa !important; /* Background color for dropdown items */
}

/* Custom Active Dropdown Item Styles */
.custom-dropdown-item.active,
.custom-dropdown-item:active {
  background-color: #e2e6ea !important; /* Lighter gray for active item, replace as needed */
}

/* Custom Styles for the discount badge */
.discount-badge-on-button {
  background-color: #feb9b9 !important; /* Pink background */
  padding: 2px 4px;
  color: #610000; /* Dark red text */
  position: relative;
  right: -10px; /* Adjust if necessary */
}

.two-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em; /* Adjust based on your line-height */
  line-height: 1.5em; /* Adjust based on your design */
}

.custom-radio .form-check-input:checked {
  background-color: #28a745;
  border-color: #28a745;
}

.custom-radio .form-check-input {
  width: 25px;
  height: 25px;
}
.custom-radio .form-check-label {
  line-height: 35px;
}
.checkout-steps-link {
  color: #139745;
  font-weight: 600; /* Bootstrap's .bg-success color */
}

/* Footer Background */
.footer {
  background-color: #d5d5d5; /* Lighter shade */
}

/* Footer Text */
.footer-text {
  color: #232221; /* .bg-neutral-100 for high contrast */
}

.payment-icons {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Adjust as needed */
}

.payment-icon {
  width: 120px; /* Adjust as needed */
  height: auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Adjust as needed */
}

.social-icon {
  width: 50px; /* Adjust as needed */
  height: auto;
}

.btn:disabled {
  background-color: #8fd27e;
}
.btn-lg:disabled {
  background-color: #8fd27e;
}
.border-change {
  border: 1px solid #8fd27e;
  box-shadow: #8fd27e 2px 2px 4px 4px;
}

.rounded-lg {
  border-radius: 1rem; /* Adjust radius as needed */
}

.large-checkbox .form-check-input {
  transform: scale(1.5);
}

.large-checkbox .form-check-input:checked {
  background-color: #139745;
  border-color: #139745;
}

/* For the Rich Text Editor */

.wrapper-class {
  padding: 2px;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: white;
  padding: 2px;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.preview-description {
  padding: 1rem;
  margin-top: 1rem;
}
.btn-custom,
.btn-custom:active,
.btn-custom:focus {
  background-color: #139745 !important; /* Use your desired hover color */
  border-color: #139745 !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}
.btn-custom:hover {
  background-color: #72c364 !important; /* Use your desired color */
  border-color: #72c364 !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}
.btn-remove,
.btn-remove:active,
.btn-remove:focus {
  background-color: #ac0000 !important; /* Use your desired hover color */
  border-color: #ac0000 !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}
.btn-remove:hover {
  background-color: #850808 !important; /* Use your desired color */
  border-color: #850808 !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}

.btn-checkout,
.btn-checkout:active,
.btn-checkout:focus {
  background-color: #2e8b57 !important; /* Use your desired hover color */
  border-color: #2e8b57 !important; /* Match border color if needed */
  box-shadow: none !important; /* checkouts the glow effect */
}
.btn-checkout:hover {
  background-color: #246942 !important; /* Use your desired color */
  border-color: #246942 !important; /* Match border color if needed */
  box-shadow: none !important; /* Removes the glow effect */
}

/* 
Success Page */

.checkmark-animation {
  position: relative;
  animation: bounceIn 0.5s ease-in-out;
}

.checkmark-icon {
  width: 100px;
}

.success-message {
  margin-top: 20px;
  animation: fadeInUp 0.5s ease-in-out;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
